import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ImagesData from "../../components/images"

const SolarLamanDiwaPage = () => (
  <Layout>
    <SEO title="Solar Laman Diwa (non hanging)" />
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-12 flex-center justify-center position-ref font-weight-bold">
          <h3 className="agney-rainbow"><b>Solar Laman Diva (non hanging)</b></h3>
        </div>
      </div> <br/>
      <div className="row">
        <div className="col-xs-3 col-md-3">
          <ImagesData imgName="laman_diwa" className="img-thumbnail img-responsive w-100 center-block" width="100%" alt="Agneya Products" title="Agneya Special Solar Products"/>
        </div>
        <div className="col-xs-9 col-md-9">
          <ol>
            <li><b className="font-weight-bold">Specifications</b> :
              <ul>
                <li>Laman Diwa material - White Metal</li>
                <li>Height - 15cm (approx)</li>
                <li>Base Acrylic Material (10cm*10cm*3.5cm)</li>
                <li>Count of Led lights - 5</li>
                <li>Solar charging : Min 6-8 hrs (good sunlight)</li>
              </ul>
            </li>
            <li>
<b className="font-weight-bold">Utilization</b> : 10 - 12 Hrs. (fully charged)
            </li>
            <li>
<b className="font-weight-bold">Additional feature</b> : It has USB facility 
(can be charged with mobile charger incase of low sunlight)
            </li>
            <li>
<b className="font-weight-bold">MRP</b> : ₹ 1900/-
            </li>
          </ol>
        </div>
      </div> <br/>
      <div className="row">
        <div className="col-xs-12 col-md-12 font-weight-bold">
          <h4><b>User Guide</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
The box will act as a solar charger of your Laman Diwa. <b className="font-weight-bold">Before first use, keep the box for charging in the sunlight for atleast one day. </b>

For charging keep the box bit tilted (facing solar panel towards sunlight) at place where it can get maximum sunlight during the day.

Once its charged, then match the two signs on the box with the signs on the bottom of the Laman Diwa & press it. And its done. Your Laman Diwa is lighten up and ready to use.

To switch off the Laman Diwa, rotate it in the direction of the arrow.
        </div>
      </div> <br/>
      <div className="row">
        <div className="col-xs-12 col-md-12 font-weight-bold">
          <h4><b>Precautions</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
Every morning, detach the box from Laman Diwa and keep it for charging. A simple pull will separate the Laman Diwa from the box. 
<b className="font-weight-bold"> Please be careful that the buttons on the box are not touching any metal surface while charging. </b>

If Laman Diwa gets deem, detach it from the base. Please don’t use the Laman Diwa till box gets charged sufficiently.

If you find that there is low sunlight, don't worry. Keep it for charging. It will still get charged but it will require more time than usual.

If Laman Diwa is not in use, then it should always be kept separate from the box. Don’t keep the box in cupboard. Keep the box in the light (facing solar panel towards sunlight). It will prevent self discharg.

If there is no sunlight, mobile charger can be used for charging. It is SOLAR DIWA, so please use mobile charger only in the absence of sunlight. Overcharging through mobile charger, may damage the battery. If any doubt, feel free to contact.
        </div>
      </div> <br/>
    </div>
  </Layout>
)

export default SolarLamanDiwaPage
